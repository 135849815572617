<template>
  <div eagle-form-item="readonly-text">
    <component
      v-if="containerComponent"
      :is="containerComponent"
      :to="route"
      target="_blank"
      outlined
      dense
      readonly
      :value="text"
    >
      {{text}}
    </component>

    <div v-if="!containerComponent">
      {{text}}
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
import {VTextField, VTextarea } from 'vuetify/lib/components'
export default {
  mixins: [formItemMixin],
  computed: {
    route() {
      if(typeof this.formDataItem.route != 'function') {
        return
      }

      return this.formDataItem.route(this.storeData, this.formInfo)
    },
    containerComponent() {
      if(this.route) return 'router-link'
      if(this.formDataItem.containerComponent === 'text') return VTextField
      if(this.formDataItem.containerComponent === 'textarea') return VTextarea
      return null
    },
    text() {
      if(typeof this.formDataItem.text === 'function') {
        return this.formDataItem.text(this.storeData, this.formInfo)
      }

      let text = this.storeData

      if(this.formDataItem.json === true) {
        if(text instanceof Object) return JSON.stringify(text)
        if(Array.isArray(text)) return JSON.stringify(text)
        return text || ''
      }

      if(this.formDataItem.prefix) {
        text = `${this.formDataItem.prefix}.${text}`
      }

      if(this.formDataItem.suffix) {
        text = `${text}.${this.formDataItem.suffix}`
      }

      if(this.formDataItem.i18n === true) {
        text = this.$t(text)
      }

      return text
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
